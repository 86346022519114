import * as tslib_1 from "tslib";
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import moment from 'moment';
import { URLS } from '@/common/js/constant/index';
let DrawerRedeemVoucherDetails = class DrawerRedeemVoucherDetails extends Vue {
    constructor() {
        super(...arguments);
        this.isRedeemLoading = false;
        this.isShowRedeemSuccess = false;
    }
    get formatedVoucherCode() {
        return this.info.voucher_code
            && this.info.voucher_code.replace(/\D/g, '')
                .replace(/(.{4})/g, '$1 ');
    }
    get isToday() {
        return moment().isSame(this.info.participate_time, 'day');
    }
    get hasRedeemableUnit() {
        return this.info.redeemable_unit_info && this.info.redeemable_unit_info.length;
    }
    redeem() {
        this.isRedeemLoading = true;
        this.$ajax.post(URLS.redeemVoucher, {
            voucher_code: this.info.voucher_code,
        }).then((res) => {
            this.isShowRedeemSuccess = true;
        }).finally(() => {
            this.isRedeemLoading = false;
        });
    }
    onContivue() {
        this.isShowRedeemSuccess = false;
        this.isShowDrawer = false;
        this.$emit('success');
    }
};
tslib_1.__decorate([
    Prop({ type: Object, default: () => ({}) })
], DrawerRedeemVoucherDetails.prototype, "info", void 0);
tslib_1.__decorate([
    PropSync('show', { type: Boolean })
], DrawerRedeemVoucherDetails.prototype, "isShowDrawer", void 0);
DrawerRedeemVoucherDetails = tslib_1.__decorate([
    Component
], DrawerRedeemVoucherDetails);
export default DrawerRedeemVoucherDetails;
