import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
import RedeemVoucherScanner from './redeem-voucher-scanner.vue';
import DrawerRedeemVoucherDetails from './drawer-redeem-voucher-details.vue';
let RedeemVouncher = class RedeemVouncher extends Vue {
    constructor() {
        super(...arguments);
        this.redeemCode = 'KLK';
        this.isVerifyLoading = false;
        this.isShowScanner = false;
        this.isShowRedeemDrawer = false;
        this.voucherInfo = {};
    }
    /**
     * 兑换码输入框回车事件
     * @param {Object} event 事件对象
     */
    onInputEnter(event) {
        event.target.blur();
        this.verifyVoucher();
    }
    /**
     * 扫描兑换码成功
     * @param {String} code 扫描到的兑换码
     */
    onScanSuccess(code) {
        this.redeemCode = code;
        this.verifyVoucher();
    }
    verifyVoucher() {
        if (!this.redeemCode) {
            return;
        }
        this.isVerifyLoading = true;
        this.$ajax.get(URLS.getVoucherInfo, {
            voucher_code: this.redeemCode,
        }).then((res) => {
            this.voucherInfo = res;
            this.isShowRedeemDrawer = true;
        }).finally(() => {
            this.isVerifyLoading = false;
        });
    }
};
RedeemVouncher = tslib_1.__decorate([
    Component({
        components: {
            RedeemVoucherScanner,
            DrawerRedeemVoucherDetails,
        },
    })
], RedeemVouncher);
export default RedeemVouncher;
